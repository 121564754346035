@font-face {
    font-family: 'SmartSuite';
    src: url('../fonts/smartsuite.eot?o57evk');
    src: url('../fonts/smartsuite.eot?o57evk#iefix') format('embedded-opentype'),
        url('../fonts/smartsuite.ttf?o57evk') format('truetype'),
        url('../fonts/smartsuite.woff?o57evk') format('woff'),
        url('../fonts/smartsuite.svg?o57evk#SmartSuite') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'SmartSuite' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bag_white:before {
    content: '\e901';
    color: #fff;
}
.icon-chevron:before {
    content: '\e900';
}
.icon-circle_filled:before {
    content: '\e902';
}
.icon-circle:before {
    content: '\e903';
}
.icon-bag_generic:before {
    content: '\e904';
}
.icon-bag_loading_aircraft:before {
    content: '\e905';
}
.icon-bag_loading_checkin:before {
    content: '\e906';
}
.icon-bag_loading_delivered:before {
    content: '\e907';
}
.icon-bag_loading_ready:before {
    content: '\e908';
}
.icon-SVG_OutOfGaugeBag:before {
    content: '\e909';
}
.icon-SVG_LateBag:before {
    content: '\e90a';
}
.icon-SVG_ProblemDockBag:before {
    content: '\e90b';
}
.icon-SVG_SmartDrop:before {
    content: '\e90c';
}
.icon-SVG_SmartNotify:before {
    content: '\e90d';
}
.icon-SVG_SmartPass:before {
    content: '\e90e';
}
.icon-SVG_SmartShip:before {
    content: '\e90f';
}
.icon-SVG_SmartTrack:before {
    content: '\e910';
}
.icon-kpi_icon:before {
    content: '\e911';
}
.icon-uncategorized_bag:before {
    content: '\e912';
}
.icon-mode_coldbag:before {
    content: '\e913';
}
.icon-zoom-ResetView:before {
    content: '\e914';
}
.icon-carrier_flight_toggleview_off:before {
    content: '\e915';
}
.icon-mode_goalmissedbag:before {
    content: '\e916';
}
.icon-mode_hotbag:before {
    content: '\e917';
}
.icon-mode_mechanical:before {
    content: '\e918';
}
.icon-mode_operational:before {
    content: '\e919';
}
.icon-mode_rejectedbag:before {
    content: '\e91a';
}
.icon-mode_security:before {
    content: '\e91b';
}
.icon-mode_system:before {
    content: '\e91c';
}
.icon-mode_mechanical2:before {
    content: '\e91d';
}
.icon-SVG_AdvancedSearch:before {
    content: '\e91e';
}
.icon-SVG_Equipment:before {
    content: '\e91f';
}
.icon-SVG_Help:before {
    content: '\e920';
}
.icon-SVG_MEC_BSD:before {
    content: '\e921';
}
.icon-SVG_Security:before {
    content: '\e922';
}
.icon-SVG_SmartConnect:before {
    content: '\e923';
}
.icon-SVG_SmartSort:before {
    content: '\e924';
}
.icon-SVG_SmartSuite:before {
    content: '\e925';
}
.icon-SVG_Subscriptions:before {
    content: '\e926';
}
.icon-SVG_Diagnostics:before {
    content: '\e927';
}
.icon-SVG_Container:before {
    content: '\e928';
}
.icon-SVG_Flight_Departure:before {
    content: '\e929';
}
.icon-SVG_AircraftType:before {
    content: '\e92a';
}
.icon-SVG_Alert_Bell:before {
    content: '\e92b';
}
.icon-SVG_ArrowDown:before {
    content: '\e92c';
}
.icon-SVG_ArrowLeft:before {
    content: '\e92d';
}
.icon-SVG_ArrowLeft2:before {
    content: '\e92e';
}
.icon-SVG_ArrowRight:before {
    content: '\e92f';
}
.icon-SVG_ArrowRight2:before {
    content: '\e930';
}
.icon-SVG_ArrowTrendDOWN:before {
    content: '\e931';
}
.icon-SVG_ArrowTrendNEUTRAL:before {
    content: '\e932';
}
.icon-SVG_ArrowTrendUP:before {
    content: '\e933';
}
.icon-SVG_ArrowUp:before {
    content: '\e934';
}
.icon-SVG_Bag:before {
    content: '\e935';
}
.icon-SVG_Bag_Hot:before {
    content: '\e936';
}
.icon-SVG_Bag_Location:before {
    content: '\e937';
}
.icon-SVG_Bag_Tag:before {
    content: '\e938';
}
.icon-uniE911:before {
    content: '\e939';
}
.icon-uniE912:before {
    content: '\e93a';
}
.icon-uniE913:before {
    content: '\e93b';
}
.icon-uniE914:before {
    content: '\e93c';
}
.icon-uniE915:before {
    content: '\e93d';
}
.icon-uniE916:before {
    content: '\e93e';
}
.icon-SVG_Barcode1:before {
    content: '\e93f';
}
.icon-SVG_Car:before {
    content: '\e940';
}
.icon-SVG_Carrier:before {
    content: '\e941';
}
.icon-SVG_CheckMark:before {
    content: '\e942';
}
.icon-SVG_Checkmark2:before {
    content: '\e943';
}
.icon-SVG_Comment:before {
    content: '\e944';
}
.icon-SVG_Copy:before {
    content: '\e945';
}
.icon-SVG_Count:before {
    content: '\e946';
}
.icon-SVG_Customs:before {
    content: '\e947';
}
.icon-SVG_Date:before {
    content: '\e948';
}
.icon-SVG_Delete:before {
    content: '\e949';
}
.icon-SVG_Duplicate:before {
    content: '\e94a';
}
.icon-SVG_Edit:before {
    content: '\e94b';
}
.icon-SVG_Engine:before {
    content: '\e94c';
}
.icon-SVG_Favourite:before {
    content: '\e94d';
}
.icon-SVG_Flight:before {
    content: '\e94e';
}
.icon-SVG_Gate:before {
    content: '\e94f';
}
.icon-SVG_Graph:before {
    content: '\e950';
}
.icon-SVG_Group:before {
    content: '\e951';
}
.icon-SVG_Health_PLC:before {
    content: '\e952';
}
.icon-SVG_Health_System:before {
    content: '\e953';
}
.icon-SVG_Home:before {
    content: '\e954';
}
.icon-SVG_Hot:before {
    content: '\e955';
}
.icon-SVG_Induct:before {
    content: '\e956';
}
.icon-SVG_Info:before {
    content: '\e957';
}
.icon-SVG_Info2:before {
    content: '\e958';
}
.icon-SVG_Keyboard:before {
    content: '\e959';
}
.icon-SVG_Location:before {
    content: '\e95a';
}
.icon-SVG_Menu:before {
    content: '\e95b';
}
.icon-SVG_Menu_System:before {
    content: '\e95c';
}
.icon-SVG_Passenger:before {
    content: '\e95d';
}
.icon-SVG_Paste:before {
    content: '\e95e';
}
.icon-SVG_Person:before {
    content: '\e95f';
}
.icon-SVG_Pier:before {
    content: '\e960';
}
.icon-SVG_Pier_D:before {
    content: '\e961';
}
.icon-SVG_Pier_Jam:before {
    content: '\e962';
}
.icon-SVG_Pier_Late:before {
    content: '\e963';
}
.icon-SVG_Question:before {
    content: '\e964';
}
.icon-SVG_Question2:before {
    content: '\e965';
}
.icon-SVG_Refresh:before {
    content: '\e966';
}
.icon-SVG_Reports:before {
    content: '\e967';
}
.icon-SVG_Route:before {
    content: '\e968';
}
.icon-SVG_Search:before {
    content: '\e969';
}
.icon-SVG_Settings_Cog:before {
    content: '\e96a';
}
.icon-SVG_Settings_Tools:before {
    content: '\e96b';
}
.icon-SVG_Settings_Wrench:before {
    content: '\e96c';
}
.icon-SVG_Sort:before {
    content: '\e96d';
}
.icon-SVG_Station:before {
    content: '\e96e';
}
.icon-SVG_Stop:before {
    content: '\e96f';
}
.icon-SVG_Subtract:before {
    content: '\e970';
}
.icon-SVG_Time:before {
    content: '\e971';
}
.icon-SVG_Time2:before {
    content: '\e972';
}
.icon-SVG_User:before {
    content: '\e973';
}
.icon-SVG_View_Hide:before {
    content: '\e974';
}
.icon-SVG_View_Hide2:before {
    content: '\e975';
}
.icon-SVG_View_Show:before {
    content: '\e976';
}
.icon-SVG_Warning:before {
    content: '\e977';
}
.icon-SVG_Warning2:before {
    content: '\e978';
}
.icon-SVG_Missed:before {
    content: '\e979';
}
.icon-SVG_X:before {
    content: '\e97a';
}
.icon-SVG_Add:before {
    content: '\e97b';
}
.icon-SVG_Download:before {
    content: '\e97c';
}
.icon-SVG_X2:before {
    content: '\e97d';
}
.icon-SVG_X_Close:before {
    content: '\e97e';
}
.icon-SVG_Flight_Arrival:before {
    content: '\e97f';
}
