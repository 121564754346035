@font-face {
    font-family: 'microsite';
    src: url('../fonts/microsite.eot?vhb4zh');
    src: url('../fonts/microsite.eot?vhb4zh#iefix') format('embedded-opentype'),
        url('../fonts/microsite.ttf?vhb4zh') format('truetype'),
        url('../fonts/microsite.woff?vhb4zh') format('woff'),
        url('../fonts/microsite.svg?vhb4zh#microsite') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='microsite-'],
[class*=' microsite-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'microsite' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.microsite-bag-error:before {
    content: '\e800';
}
.microsite-bag-question:before {
    content: '\e801';
}
.microsite-bag-success:before {
    content: '\e802';
}
.microsite-no-colour:before {
    content: '\e803';
}
