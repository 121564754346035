.animated-loader-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
.animated-loader {
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinAnimation 1.1s infinite linear;
  animation: spinAnimation 1.1s infinite linear;
  border-radius: 50%;
}
.animated-loader.animated-loader__page-loader,
.animated-loader .animated-loader__page-loader:after {
  border: 0.4em solid rgba(29, 174, 255, 0.2);
  border-left: 0.4em solid #1DAEFF;
  width: 4em;
  height: 4em;
}
.animated-loader.animated-loader__button,
.animated-loader .animated-loader__button :after {
  border: 0.2em solid rgba(29, 174, 255, 0.2);
  border-left: 0.2em solid #1DAEFF;
  margin: 0;
  width: 1em;
  height: 1em;
}
.animated-loader.x2 {
  width: 2em;
  height: 2em;
}
.animated-loader.x4 {
  width: 4em;
  height: 4em;
}
@-webkit-keyframes spinAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.flex {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.ticket-header-border-right {
  border-right: 1px solid #d0d0ce;
  border-bottom: none;
}
.ticket-header-border-bottom {
  border-right: none;
  border-bottom: 1px solid #d0d0ce;
}
.ticket {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d0ce;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 1px rgba(44, 42, 41, 0.0005);
  -moz-box-shadow: 0 1px 1px rgba(44, 42, 41, 0.0005);
  box-shadow: 0 1px 1px rgba(44, 42, 41, 0.0005);
}
.ticket > .ticket__header {
  border-right: none;
  border-bottom: 1px solid #d0d0ce;
  padding: 10px;
  font-size: 1.3em;
  background-color: #63666a;
}
.ticket > .ticket__body {
  padding: 10px;
  flex: 1;
  -ms-flex: 1 1 auto;
}
.ticket--xs-flex-direction-row {
  flex-direction: row;
}
.ticket--xs-flex-direction-row > .ticket__header {
  border-right: 1px solid #d0d0ce;
  border-bottom: none;
}
@media (min-width: 768px) {
  .ticket {
    flex-direction: row;
  }
  .ticket > .ticket__header {
    border-right: 1px solid #d0d0ce;
    border-bottom: none;
  }
  .ticket--md-flex-direction-row,
  .ticket--lg-flex-direction-row,
  .ticket--always-flex-direction-column {
    flex-direction: column;
  }
  .ticket--md-flex-direction-row > .ticket__header,
  .ticket--lg-flex-direction-row > .ticket__header,
  .ticket--always-flex-direction-column > .ticket__header {
    border-right: none;
    border-bottom: 1px solid #d0d0ce;
  }
}
@media (min-width: 992px) {
  .ticket--md-flex-direction-row {
    flex-direction: row;
  }
  .ticket--md-flex-direction-row > .ticket__header {
    border-right: 1px solid #d0d0ce;
    border-bottom: none;
  }
  .ticket--lg-flex-direction-row,
  .ticket--always-flex-direction-column {
    flex-direction: column;
  }
  .ticket--lg-flex-direction-row > .ticket__header,
  .ticket--always-flex-direction-column > .ticket__header {
    border-right: none;
    border-bottom: 1px solid #d0d0ce;
  }
}
@media (min-width: 1200px) {
  .ticket--lg-flex-direction-row {
    flex-direction: row;
  }
  .ticket--lg-flex-direction-row > .ticket__header {
    border-right: 1px solid #d0d0ce;
    border-bottom: none;
  }
  .ticket--always-flex-direction-column {
    flex-direction: column;
  }
}
.brock-react-button {
  display: flex;
  align-content: center;
  justify-content: center;
  border: 1px solid #AFAFAF;
  padding: 0.5em 1em;
  font-size: inherit;
  outline: none;
  cursor: pointer;
}
.brock-react-button * {
  cursor: pointer;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  margin: inherit;
}
.brock-react-button--default {
  color: #006272;
  background-color: #fff;
}
.brock-react-button--default:active,
.brock-react-button--default :visited {
  color: #fff;
  background-color: #000a0c;
}
.brock-react-button--default:hover {
  color: #fff;
  background-color: #006272;
}
.brock-react-button--affirmative {
  color: #86CC00;
  background-color: #fff;
}
.brock-react-button--affirmative:active,
.brock-react-button--affirmative :visited {
  color: #fff;
  background-color: #436600;
}
.brock-react-button--affirmative:hover {
  color: #fff;
  background-color: #86CC00;
}
.brock-react-button--negative {
  color: #EC1C24;
  background-color: #fff;
}
.brock-react-button--negative:active,
.brock-react-button--negative :visited {
  color: #fff;
  background-color: #950d12;
}
.brock-react-button--negative:hover {
  color: #fff;
  background-color: #EC1C24;
}
.brock-react-button:disabled {
  color: #AFAFAF;
  background-color: #fff;
  cursor: not-allowed;
}
.brock-react-button:disabled * {
  cursor: not-allowed;
}
.alertCell--success {
  color: #fff;
  background-color: #86CC00;
}
.alertCell--info {
  color: #575757;
  border: 0.5px solid #63666a;
}
.alertCell--warning {
  color: #212121;
  background-color: #FFCC00;
}
.alertCell--critical {
  color: #fff;
  background-color: #EC1C24;
}
.feed-back-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 10px 0;
}
.feed-back-container--success {
  color: #fff;
  background-color: #86CC00;
}
.feed-back-container--info {
  color: #575757;
  border: 0.5px solid #63666a;
}
.feed-back-container--warning {
  color: #212121;
  background-color: #FFCC00;
}
.feed-back-container--error {
  color: #fff;
  background-color: #EC1C24;
}
.feed-back-container--error > .feed-back-container__error-message {
  flex-grow: 1;
  flex-direction: column;
}
.feed-back-container--error > .feed-back-container__error-message > .error-message__title {
  margin-top: 0;
}
.feed-back-container--error > .btn-danger {
  height: 30px;
}
.feed-back-container--error > .btn-danger:hover {
  border-color: #fff;
}
p {
  margin: 0;
  padding: 0;
}
.ticket {
  min-height: 75px;
  border: 0;
}
.ticket > .ticket__header {
  padding: 10px 5px;
  background-color: inherit;
  display: flex;
  align-items: center;
  width: calc(8.75 * 12px);
}
.ticket > .ticket__body {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.customer-button {
  border-radius: 28px;
  border: 2px solid #006272;
  color: #fff;
  background: #006272;
  white-space: nowrap;
  font-size: 14px;
  padding: 12px 20px 8px 20px;
  width: auto;
  outline: none;
  text-align: center;
  cursor: pointer;
  font-family: inherit;
  -moz-transform: translate(0px, -1px);
  -ms-transform: translate(0px, -1px);
  -o-transform: translate(0px, -1px);
  -webkit-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
  transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.customer-button:hover,
.customer-button:focus {
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.4);
}
.customer-button:focus:active,
.customer-button:visited {
  box-shadow: none;
}
.customer-button--primary:hover,
.customer-button--primary:focus {
  background-color: #00788c;
  border-color: #00788c;
}
.customer-button--primary:focus:active,
.customer-button--primary:visited {
  background-color: #004c59;
  border-color: #004c59;
}
.customer-button--secondary {
  color: #006272;
  background-color: #fff;
  border: 2px solid #00788c;
}
.customer-button--secondary:hover,
.customer-button--secondary:focus {
  color: #006272;
  background-color: #f2f7f8;
}
.customer-button--secondary:focus:active,
.customer-button--secondary:visited {
  background-color: #e5eff0;
  color: #006272;
  border: 2px solid #e5eff0;
}
.customer-link {
  text-decoration: underline;
  color: #2c2a29;
  font-size: 16px;
}
.customer-link:hover {
  text-decoration: none;
  color: #00788c;
}
.header {
  border-bottom: 2px solid #84bd00;
}
.feed-back-container {
  width: inherit;
}
.passenger {
  line-height: 23px;
}
.bag-event-icon-info-container {
  line-height: 17px;
}
.info > p {
  line-height: 23px;
}
@media (min-width: 768px) {
  .ticket > .ticket__header {
    padding: 10px;
    width: calc(8.75 * 16px);
  }
  .landing-page__submit-button {
    padding: 16px 25px 12px 24px;
    border-radius: 28px;
    font-size: 18px;
  }
}
body {
  margin: 0;
  background-color: #f9f9f9;
}
.feed-back-container--info {
  padding-top: 0;
  padding-bottom: 0;
}
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100vw;
  font-family: 'Diodrum Regular', Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #2c2a29;
  margin: auto;
  background-color: #fff;
}
.header {
  background-color: #fff;
}
.header__customer-image {
  width: 197px;
}
.header__route-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #006272;
  padding: 10px 0;
  color: #fff;
}
.header__route {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 0 0 5px;
  font-weight: bold;
}
.header__flight-info-container {
  font-size: 16px;
  display: flex;
}
.header__flight-info {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.header__flight-info p + p {
  margin-left: 0.3em;
}
.header__flight-info {
  margin-left: 10px;
}
.header__flight-number {
  white-space: nowrap;
}
.header__flight-route-icon {
  padding: 0 20px;
  font-size: 12px;
}
.footer {
  font-family: 'Diodrum Regular', Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #2c2a29;
  background-color: #fff;
}
.body {
  overflow-y: auto;
  padding: 30px;
}
.loader-container {
  padding: 10px;
}
.landing-page {
  background-color: #fff;
}
.landing-page .info {
  font-size: 16px;
}
.landing-page__header {
  border-bottom: 1px solid #d0d0ce;
}
.landing-page__body {
  padding: 16px;
}
.landing-page__title {
  font-weight: normal;
  font-size: 28px;
  color: #2c2a29;
  margin: 20px 0;
}
.landing-page__form-container {
  background-color: #f9f9f9;
  border-radius: 6px 6px 2px 2px;
  padding: 20px;
  margin-bottom: 30px;
}
.landing-page__form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  margin-top: 10px;
}
.landing-page__form > *:not(:last-child) {
  margin-bottom: 10px;
}
.landing-page__submit-button {
  width: 100%;
}
.landing-page__information-link {
  padding-bottom: 10px;
}
.passenger {
  font-size: 16px;
}
.passenger__name-row {
  display: flex;
  align-items: center;
}
.passenger__name {
  font-weight: bold;
}
.passenger__business-class {
  background-image: linear-gradient(to right, #bbbcbc, #d0d0ce);
  border-radius: 28px;
  padding: 0 7px;
  font-size: 10px;
  margin-left: 10px;
}
.bag {
  overflow-y: auto;
}
.bag__list-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
  padding-top: 10px;
}
.bag__info-container {
  display: inline-flex;
  align-items: flex-end;
  background-color: #2c2a29;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px 0;
}
.bag__info-container > i[class^='icon-'] {
  font-size: 20px;
  margin-right: 5px;
}
.bag__bag-event {
  width: 100%;
  position: relative;
  padding: 10px 0 20px 20px;
  border-left: 1px solid #d0d0ce;
}
.bag__bag-event--previous {
  color: #63666a;
}
.bag__bag-event--previous .bag-event-icon-info-container__icon {
  color: #d0d0ce;
}
.bag__bag-event--previous .bag__bag-event-date-icon {
  color: #d0d0ce;
}
.bag__bag-event--last {
  border-color: #fff;
  padding-bottom: 0;
}
.bag__bag-event-date-icon {
  position: absolute;
  top: -7px;
  left: -5px;
  background-color: #fff;
  color: #006272;
  font-size: 10px;
}
.bag__bag-event-date {
  text-transform: uppercase;
  position: absolute;
  top: -7px;
  left: 20px;
}
.bag__bag-events-container {
  padding: 0 5px;
}
.bag-event-icon-info-container {
  display: flex;
}
.bag-event-icon-info-container__icon {
  padding: 0 10px;
  border-right: 1px solid #f1f1f1;
}
.bag-event-icon-info-container__info {
  padding-top: 5px;
  padding-left: 10px;
}
.bag-event-icon-info-container__date {
  text-transform: uppercase;
  font-weight: bold;
}
.latest-events {
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 10px;
  margin: 10px;
  border-top: 1px solid #f1f1f1;
}
.latest-events__event-container {
  color: #2c2a29;
  margin-bottom: 5px;
}
.latest-events__details-link {
  text-decoration: none;
}
.latest-events__details-link:hover {
  text-decoration: none;
}
.latest-events__bag-event {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  background-color: #f2f7f8;
  border-radius: 2px;
}
.latest-events__more-info-icon {
  font-size: 12px;
  color: #006272;
  margin-right: 10px;
}
.latest-events__no-declarations-message {
  padding: 10px;
}
.latest-events .bag-event-icon-info-container {
  padding: 10px 0;
}
.latest-events .bag-event-icon-info-container__description,
.latest-events .bag-event-icon-info-container__location {
  display: inline;
}
.latest-events .bag-event-icon-info-container__icon {
  border-color: #d0d0ce;
}
.bag-events {
  padding: 10px 0;
  margin: 10px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}
.bag-events .bag-event-icon-info-container {
  padding: 10px 0 20px;
  border-top: 1px solid #f1f1f1;
}
.bag-events__info-wrapper {
  margin-top: 0;
  padding: 30px;
}
.footer {
  padding: 10px 0;
  border-top: 1px solid #84bd00;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.footer button + button {
  margin-left: 15px;
}
.footer__last-refresh-label {
  font-size: 12px;
  margin-top: 10px;
}
.information-modal {
  white-space: pre-wrap;
}
@media (min-width: 768px) {
  .header {
    margin-bottom: 25px;
  }
  .header__image-container {
    max-width: 729px;
    padding: 20px 0;
    margin: auto;
  }
  .app {
    max-width: 729px;
    font-size: 16px;
    background-color: inherit;
  }
  .app .body {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.13);
  }
  .landing-page {
    margin-top: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.13);
  }
  .landing-page .header__image-container {
    padding-left: 20px;
  }
  .landing-page__body {
    padding: 30px;
  }
  .landing-page__form-container {
    padding: 30px;
  }
  .landing-page__form {
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
  }
  .landing-page__form > *:not(:last-child) {
    margin-bottom: 0;
  }
  .landing-page__submit-button-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .landing-page__submit-button {
    width: auto;
  }
  .latest-events__no-declarations-message {
    font-size: 16px;
  }
  .latest-events .bag-event-icon-info-container {
    padding: 20px 0;
  }
  .latest-events .bag-event-icon-info-container__description,
  .latest-events .bag-event-icon-info-container__location {
    font-size: 16px;
  }
  .latest-events .bag-event-icon-info-container__icon {
    padding-right: 20px;
  }
  .latest-events .bag-event-icon-info-container__info {
    padding-left: 20px;
  }
  .bag-events__info-wrapper {
    padding: 0;
    margin-top: 20.5px;
  }
  .info {
    padding-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .header {
    width: 100%;
  }
}
